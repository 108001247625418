enum GameCategories {
    GENDER = "Gender",
    AGE = "Age",
    Appearances = "Appearances",
    HOUSEMATES = "Housemates",
    SEASON = "Season",
    NAME = "Name",
    NICKNAME = "Nickname"
}

export default GameCategories