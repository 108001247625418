import React from "react";
import { GamePage } from "./Pages";
import "./App.css";

function App() {
  return (
    <div className="App">
        <GamePage />
    </div>
  );
}

export default App;
